import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SecondChance = () => {
  const hasForm = false
  const images = [
    "FOMS/DEV/shareasmile-recipient-2022-1",
    "FOMS/DEV/shareasmile-recipient-2022-2",
    "FOMS/DEV/shareasmile-recipient-2022-3"
  ]

  return (
    <SharedStateProvider>
      <Layout pageTitle="secondchance" language={"en"}>
        <SEO
          lang="en"
          title="Second Chance Program at Fresno Oral Maxillofacial Surgery & Dental Implant Center"
          description="It is time for a brand new, healthy, beautiful smile."
        />

        <div className="sc-program">
          <div className="sc__hero">
            <div className="sc__hero--desktop">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/second-chance-desktop-hero-image"
                alt="program banner"
              />
            </div>
            <div className="sc__hero--mobile">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/second-chance-mobile-hero-image"
                alt="program banner"
              />
            </div>
          </div>

          <div className="sc__section">
            <div className="">
              <div className="sc__about-grid--content">
                <p className="sc__subheading">
                  A Second Chance for a Healthy Smile{" "}
                </p>
                <br />
                <p>
                  Fresno Oral Maxillofacial Surgery & Dental Implant Center is
                  proud to announce its 2022 Second Chance recipient. This
                  life-changing program provides one deserving recipient from
                  our region with a complete smile makeover free of charge.
                </p>
                <p>
                  Selene, 41, is a pharmacy buyer for Community Regional Medical
                  Center and lives in Selma. In her application, she wrote about
                  how her teeth have been bad since her early childhood, and she
                  mentioned that both of her parents had bad teeth as well. She
                  has always felt unable to smile or look happy because of
                  embarrassment, even around her family, and especially her
                  grandchildren. Selene has been through many hard times,
                  including having a baby while being a freshman in high school,
                  but she hasn’t let anything stop her. She graduated from high
                  school on time with the rest of her class, got a job, and has
                  received support and encouragement from her then boyfriend,
                  now husband, after losing the support of her family because of
                  her early pregnancy. She wrote that she just wants to wake up
                  one day with a full set of teeth and a new smile. While her
                  husband, kids, and grandchildren know she is happy, she hasn’t
                  been able to show them through a big smile, and that is what
                  she is looking forward to most, along with a family picture
                  where she can show off her beautiful smile.
                </p>
                <br />
                <p className="sc__subheading">Full-Arch Restoration</p>
                <br />
                <p>
                  <Link
                    to="/procedure/full-arch-restoration-fresno-clovis-reedley-madera-ca/"
                    title="Learn more about full-arch restoration"
                  >
                    Full-arch restoration
                  </Link>{" "}
                  is a healthy and effective way to replace many missing teeth.
                  The procedure works by securing a full prosthesis in the mouth
                  using as few as four dental implants. The dental implant posts
                  will permanently hold your new teeth in place while also
                  preventing bone loss.
                </p>
              </div>
            </div>
          </div>

          <div className="sc__section sc__slider">
            <ImageSlider images={images} adaptiveHeight useDots />
          </div>

          {hasForm && (
            <>
              <div className="sc__apply-banner">
                <p>CLICK THE BUTTON BELOW TO FILL OUT THE FORM</p>
              </div>

              <div className="sc__section sc__form">
                <a
                  className="standard-button contained"
                  href="https://secureform.seamlessdocs.com/f/f77f55370f67e937de67fd5c9ad38c3c?embedded=true"
                  target="_blank"
                >
                  Submit Application
                </a>
              </div>
            </>
          )}

          <div className="sc__section">
            <p>
              Follow Selene’s treatment journey as we help give her a Second
              Chance to smile on our{" "}
              <a
                href="https://www.facebook.com/fresnooralsurgery"
                target="_blank"
                title="Follow us on Facebook"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/fresnooralsurgery/"
                target="_blank"
                title="Follow us on Instagram"
              >
                Instagram
              </a>{" "}
              pages!
            </p>
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedure/dental-implants-fresno-ca/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedure/full-arch-restoration-fresno-clovis-reedley-madera-ca/"
                  title="Learn more about full-arch restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SecondChance
